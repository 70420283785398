<template>
  <div class="items-center m-0 pt-3.5 intro-y lg:flex-row  ">
    <div class="flex w-full m-0 pb-6 font-medium ">
      <input
        id="tabulator-html-filter-value"
        v-model="searchText"
        type="text"
        class="w-2/5 form-control"
        placeholder="ค้นหาชื่อ นามสกุล เบอร์โทรศัพท์ และอีเมล"
        @keyup.enter="onSearch"
      />
      <button class="ml-4 shadow-md btn btn-primary" @click="onSearch">
        ค้นหา
      </button>

      <!--Start Button Modal-->
      <div class="flex justify-end w-full mt-4 sm:mt-0">
        <!--Start Export-->
        <div class="mx-1.5">
          <button
            class="btn btn-outline-secondary px-4 h-full border border-theme-33 hover:bg-gray-200 text-theme-33 text-sm font-normal focus:ring-transparent"
            aria-expanded="false"
            @click="handleExport"
          >
            <DownloadIcon class="w-4 inline-block cursor-pointer" /><span
              class="pl-2"
              >Export</span
            >
          </button>
        </div>
        <div>
          <a
            class="flex items-center mr-3 h-full"
            href="javascript:;"
            data-toggle="modal"
            data-target="#select-user-modal"
            @click="toggleModal"
          >
            <button
              :class="
                `ml-4 h-full shadow-md btn btn-primary ${
                  !countSelectedUsers ? 'opacity-50' : ''
                }`
              "
              :disabled="!countSelectedUsers"
            >
              เลือกผู้ผ่านกิจกรรม ({{ countSelectedUsers }})
            </button>
          </a>
        </div>
      </div>

      <!--Start Modal Template-->
      <div
        id="select-user-modal"
        class="modal font-prompt"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content mt-40">
            <!-- BEGIN: Modal Body -->
            <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
              <div class="col-span-12 sm:col-span-12 pt-7">
                <label for="modal-form-1" class="form-label">
                  <AlertCircleIcon class="text-theme-31 w-16 h-16" />
                </label>
                <div class="mt-5 text-xl leading-8 text-theme-32">
                  ยืนยันการเลือก ?
                </div>
                <div class="text-sm leading-5">
                  ยืนยันการเลือกผู้ผ่านกิจกรรมจากรายการที่เลือก, ใช่หรือไม่ ?
                </div>
              </div>
            </div>
            <div class="flex justify-center w-full pb-10 pt-1">
              <button
                type="button"
                data-dismiss="modal"
                class="w-36 h-10 mr-2 btn btn-primary"
                @click="onVerifyPasserUser"
              >
                ยืนยันการเลือก
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="w-20 btn btn-outline-secondary "
              >
                ยกเลิก
              </button>
            </div>
            <!-- END: Modal Body -->
          </div>
        </div>
      </div>
      <!--End Modal Template-->
    </div>

    <div class="lg:grid-cols-2 lg:h-24 md:max-w-1/2 sm:max-w-1/2">
      <div
        class="all-applicants w-full h-full rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้สมัครทั้งหมด
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            summaryRegisteredUsers
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
    </div>
    <!-- End candidate activities Summary-->

    <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
      <table class="table table-report m-0 pt-4 font-prompt">
        <thead>
          <tr>
            <th v-if="isActiveOnProd" class="whitespace-nowrap w-10">
              <input
                v-model="checkAll"
                type="checkbox"
                class="accent-orange min-w-18 min-h-18 flex content-center justify-items-center items-center cursor-pointer"
                @change="checkAllHandler"
              />
            </th>
            <th class="whitespace-nowrap">ชื่อจริง</th>
            <th class="whitespace-nowrap">นามสกุล</th>
            <th class=" whitespace-nowrap">เบอร์โทร</th>
            <th class="whitespace-nowrap">อีเมล</th>
            <th class=" whitespace-nowrap">ช่วงอายุ</th>
            <th class=" whitespace-nowrap">อาชีพ</th>
            <th class=" whitespace-nowrap text-center">วันที่ลงทะเบียน</th>
            <th class=" whitespace-nowrap text-center">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in registeredUsers"
            :key="user.id"
            class="intro-x max-h-14 text-sm font-normal"
          >
            <td v-if="isActiveOnProd" class="w-10">
              <input
                :id="user.id"
                v-model="user.checked"
                :checked="user.is_qualify ? true : false"
                type="checkbox"
                :value="user.id"
                :disabled="user.is_qualify || user.is_delete"
                class="accent-orange min-w-18 min-h-18 flex content-center justify-items-center items-center cursor-pointer"
                @change="handleChecked"
              />
            </td>
            <td v-show="user.is_delete" colspan="6">
              <div
                :class="
                  user.is_delete
                    ? 'flex text-theme-1 items-center text-delete'
                    : ''
                "
              >
                <AlertCircleIcon
                  v-if="user.is_delete"
                  class="block w-4 h-4 stroke-2"
                />
                <span
                  :class="user.is_delete ? 'font-prompt font-medium pl-2' : ''"
                >
                  {{ user.is_delete ? "ผู้ใช้งานลบบัญชี" : user.first_name }}
                </span>
              </div>
            </td>
            <td v-show="!user.is_delete">
              {{ user.first_name }}
            </td>
            <td v-if="!user.is_delete" class="">
              {{ user.last_name }}
            </td>
            <td v-if="!user.is_delete" class="">
              {{
                `${user.phone_number ? "0" : ""}` +
                  user.phone_number?.substring(3)
              }}
            </td>
            <td v-if="!user.is_delete" class="">
              {{ user.email }}
            </td>
            <td v-if="!user.is_delete" class="">
              {{ user.age_name }}
            </td>
            <td v-if="!user.is_delete" class="">
              {{ user.career_name }}
            </td>
            <td class="text-center">
              {{ getDateFormat(user.created_at) }}
            </td>
            <td class="text-center">
              <EyeIcon
                class="w-4 cursor-pointer inline-block"
                @click="onViewDetail(user.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0"
        @change="onPageSizeHandle()"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>

    <div
      id="detail-registered-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto my-8 w-1/2">
        <div class="modal-content">
          <button
            type="button"
            class="v-dialog-close"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span class="v-btn__content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#5E5873"
                class="h-6 w-6 m-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
          <div class="pt-8 pb-12 modal-body px-16">
            <div class="text-center mt-6">
              <p class="bold text-2xl font-medium header-color">
                รายละเอียดผู้ลงทะเบียน
              </p>
              <p class="mt-2 text-theme-36">ข้อมูลในการกรอกของผู้ลงทะเบียน</p>
            </div>
            <div class="mt-8">
              <p class="text-lg font-medium">
                ส่วนที่ 1 : ข้อมูลส่วนบุคคลของผู้สมัครเข้าร่วมอบรม
              </p>
            </div>
            <div
              v-show="userRegisterDetail.is_delete"
              class="grid grid-cols-1 bg-primary-12 rounded-lg mt-5"
            >
              <div class="py-2.5 px-3.5">
                <div class="flex font-medium items-center text-theme-1">
                  <div>
                    <AlertCircleIcon class="block w-4 h-4 stroke-2" />
                  </div>
                  <div
                    class="
                    font-prompt font-medium pl-1.5 flex
                  "
                  >
                    ผู้ใช้งานลบบัญชี
                  </div>
                </div>
                <div class="text-theme-33 pt-1 text-bottom">
                  ไม่มีข้อมูลผู้สมัครในระบบ
                  เนื่องจากผู้ใช้งานขอลบบัญชีออกจากระบบแล้ว
                </div>
              </div>
            </div>
            <div
              v-show="!userRegisterDetail.is_delete"
              class="grid grid-cols-3 grid-flow-row gap-x-16 gap-y-7 mt-7"
            >
              <div>
                <p>ชื่อจริง</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.first_name || "-" }}
                </p>
              </div>
              <div>
                <p>นามสกุล</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.last_name || "-" }}
                </p>
              </div>
              <div>
                <p>ชื่อเล่น</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.nick_name || "-" }}
                </p>
              </div>
              <div>
                <p>จังหวัดที่อาศัยอยู่ในปัจจุบัน</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.province_name || "-" }}
                </p>
              </div>
              <div>
                <p>วันเดือนปีเกิด</p>
                <p class="font-semibold">
                  {{
                    $h.formatDate(
                      userRegisterDetail?.date_of_birth,
                      "DD/MM/YYYY"
                    ) || "-"
                  }}
                </p>
              </div>
              <div>
                <p>อายุ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.age_name || "-" }}
                </p>
              </div>
              <div>
                <p>เพศ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.gender_name || "-" }}
                </p>
              </div>
              <div>
                <p>ระดับการศึกษา</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.education_name || "-" }}
                </p>
              </div>
              <div>
                <p>รายได้ครัวเรือน</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.income_name || "-" }}
                </p>
              </div>
              <div>
                <p>อาชีพ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.career_name || "-" }}
                </p>
              </div>
              <div>
                <p>เบอร์โทร</p>
                <p class="font-semibold">
                  {{
                    `${userRegisterDetail?.phone_number ? "0" : ""}` +
                      userRegisterDetail?.phone_number?.substring(3) || ""
                  }}
                </p>
              </div>
              <div>
                <p>Line ID</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.line_id || "-" }}
                </p>
              </div>
              <div>
                <p>อีเมล</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.email || "-" }}
                </p>
              </div>
            </div>
            <hr class="mt-10 mb-7" />
            <p class="text-lg font-medium mb-4">
              ส่วนที่ 2 : แบบสำรวจความคิดเห็น ความคาดหวัง และการนำไปใช้จริง
              กิจกรรม "{{ userRegisterDetail?.activity?.name }}"
            </p>
            <div>
              <p class="text-sm font-medium">
                1. ท่านเคยเข้าคอร์สอบรม
                เรื่องการดูแลแบบประคับประคองการดูแลคุณภาพชีวิตระยะท้ายมาก่อนหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull; {{ answerYesOrNot(userRegisterDetail?.is_trained) }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                2. ท่านเคยเข้าคอร์สอบรม เรื่องการดูแลคุณภาพชีวิตระยะท้ายของ 5
                มิติองค์ความรู้มิติใดบ้าง
              </p>
              <ul
                v-if="userRegisterDetail?.courses"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li
                  v-for="course in userRegisterDetail?.courses"
                  :key="course.id"
                >
                  &bull; {{ course.name }}
                </li>
              </ul>
              <ul
                v-show="!userRegisterDetail?.courses"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  &bull; ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                3. ท่านเคยเข้าคอร์สอบรม เรื่องการดูแลแบบประคับประคอง
                การดูแลคุณภาพชีวิตระยะท้ายกับชีวามิตรมาก่อนหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ answerYesOrNot(userRegisterDetail?.is_trained_cvm) }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                4. ท่านคาดหวังอะไรบ้างจากการเข้าเรียน "{{
                  userRegisterDetail?.activity?.name
                }}" ในครั้งนี้(ตอบได้มากกว่า 1 ข้อ)
              </p>
              <ul
                v-if="userRegisterDetail?.expects"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li
                  v-for="expect in userRegisterDetail?.expects"
                  :key="expect.id"
                >
                  &bull; {{ expect.name }}
                </li>
              </ul>
              <ul
                v-show="!userRegisterDetail?.expects"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  &bull; ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                5. จงบอกเหตุผลว่าทำไมท่านถึงอยากเรียน "{{
                  userRegisterDetail?.activity?.name
                }}" ในครั้งนี้
              </p>
              <ul
                :class="[
                  `px-2.5 mt-2.5 text-sm font-normal ${
                    userRegisterDetail?.reason
                      ? 'text-theme-36'
                      : 'text-theme-31'
                  }`
                ]"
              >
                <li>&bull; {{ userRegisterDetail?.reason || "ไม่ได้กรอก" }}</li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                6. ท่านยินดีทำแบบสอบถามทุกฉบับที่กำหนดไว้ในกิจกรรมนี้หรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ userRegisterDetail?.is_pleased ? "ยอมรับ" : "ปฏิเสธ" }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                7. ท่านสะดวกเข้าร่วมกิจกรรมตามวัน - เวลา
                ที่ระบุไว้ในกิจกรรมหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ userRegisterDetail?.train_date_name || "ไม่ได้กรอก" }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                8. ข้อเสนอแนะอื่นๆ (ถ้ามี)
              </p>
              <ul
                :class="[
                  `px-2.5 mt-2.5 text-sm font-normal ${
                    userRegisterDetail?.suggest
                      ? ' text-theme-36'
                      : ' text-theme-31'
                  }`
                ]"
              >
                <li>
                  &bull; {{ userRegisterDetail?.suggest || "ไม่ได้กรอก" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Detail User Modal-->

    <!--Select Type of Export Modal-->
    <div
      id="type-export-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-full mt-40 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div
            class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center pt-8 pb-6"
          >
            <div class="col-span-12 sm:col-span-12">
              <div
                class="text-xl font-prompt leading-8 text-theme-32 font-medium"
              >
                เลือกรูปแบบไฟล์
              </div>
            </div>
          </div>
          <div
            class="mx-0 px-10
              "
          >
            <button
              class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
              @click="onConfirmExport(fileType[0])"
            >
              .xlsx
            </button>
          </div>
          <div
            class="mx-0 px-10 pt-3.5
              "
          >
            <button
              class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
              @click="onConfirmExport(fileType[1])"
            >
              .csv
            </button>
          </div>
          <div class="flex justify-center w-full pt-7 pb-9">
            <button
              type="button"
              data-dismiss="modal"
              class="w-24 h-10 btn btn-outline-secondary border-1 rounded-md border-theme-33 text-theme-33"
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--Select Type of Export Modal-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog w-460">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-5">ผิดพลาด !!</div>
              <div
                class="mt-5 text-xl font-medium leading-8 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Error-->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed, nextTick } from "vue";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { useRoute } from "vue-router";
import { helper as $h } from "@/utils/helper";
import Toastify from "toastify-js";

export default defineComponent({
  setup() {
    const route = useRoute();

    const title = ref();
    const registeredUsers = ref([]);
    const summaryRegisteredUsers = ref();
    const searchText = ref();
    const pageSize = ref([10, 20, 30, 40]);
    const pages = ref([]);
    const pageSizeSelect = ref(pageSize.value[0]);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const userRegisterDetail = ref([]);
    const activityPasser = ref([]);
    const checkAll = ref(false);
    const disabled = ref(false);
    const errorTxt = ref("");
    const isActiveOnProd = ref(true);
    const isLoading = ref(false);
    const selectedTab = ref();
    const statePath = ref();
    const fileType = ref(["xlsx", "csv"]);

    const selectedQualifiedUser = computed(() => {
      return enableUsers.value.filter(item => item.checked);
    });

    const countSelectedUsers = computed(() => {
      return selectedQualifiedUser.value.length;
    });

    const enableUsers = computed(() => {
      return registeredUsers.value.filter(
        item => !item.is_qualify && !item.is_delete
      );
    });

    const fetchUserRegisters = async () => {
      try {
        isLoading.value = true;
        let params = {
          page: pageSelect.value,
          perPage: pageSizeSelect.value,
          search: searchText?.value || undefined
        };
        const res = await getheringService.getActivityRegisteredUser(
          route.params.id,
          params
        );
        const detail = await getheringService.getDetailGathering(
          route.params.id
        );
        title.value = detail.data.data.title;
        const {
          total,
          register_amount,
          activities,
          is_active
        } = res?.data?.data?.item;
        registeredUsers.value = activities ?? [];
        summaryRegisteredUsers.value = register_amount ?? [];
        isActiveOnProd.value = is_active ?? [];

        const pageResult = $h.calculatePagination({
          totalItems: total,
          pageSize: pageSizeSelect.value,
          currentPage: pageSelect.value
        });
        pages.value = pageResult.pages;
        totalPages.value = pageResult.totalPages;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const answerYesOrNot = answer => {
      if (answer) {
        return "เคย";
      } else {
        return "ไม่เคย";
      }
    };

    const onVerifyPasserUser = async () => {
      let users = selectedQualifiedUser.value.map(item => item.id);
      let payload = {
        qualifies: users
      };
      await getheringService.updateQualifyRegisterUser(
        route.params.id,
        payload
      );
      fetchUserRegisters();
    };

    const onSearch = () => {
      pageSelect.value = 1;
      fetchUserRegisters();
    };

    const paginationSelect = page => {
      checkAll.value = false;
      pageSelect.value = page;
      fetchUserRegisters();
    };

    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY, H:mm");
    };

    const onPageSizeHandle = () => {
      pageSelect.value = 1;
      fetchUserRegisters();
    };

    const getUserDetail = async registerId => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      try {
        const res = await getheringService.getUserByRegisterId(registerId);
        userRegisterDetail.value = res.data.data.item;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const onViewDetail = async userId => {
      await getUserDetail(userId);
      await cash("#detail-registered-modal").modal("show");
    };

    const checkAllHandler = event => {
      const checked = event.target.checked;
      if (checked) {
        registeredUsers.value
          .filter(item => {
            return item.is_delete !== true;
          })
          .forEach(item => (item.checked = true));
      } else {
        enableUsers.value.forEach(item => (item.checked = false));
      }
    };

    const handleChecked = event => {
      const checked = event.target.checked;
      if (checked) {
        checkAll.value =
          enableUsers.value.length === selectedQualifiedUser.value.length;
      } else {
        checkAll.value = false;
      }
    };

    const handleExport = async () => {
      cash("#type-export-modal").modal("show");
    };

    const onConfirmExport = async fileType => {
      try {
        const res = await getheringService.getExportedFiles(
          route.params.id,
          statePath.value,
          { fileType: fileType }
        );
        $h.downloadBinaryFile(res.data, fileType);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1500,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const getStatePath = () => {
      selectedTab.value = JSON.parse(localStorage.getItem("selectedTab"));
      if (selectedTab.value.id === 2) {
        statePath.value = "register";
      }
    };

    onMounted(() => {
      fetchUserRegisters();
      getStatePath();
    });

    return {
      registeredUsers,
      summaryRegisteredUsers,
      isLoading,
      searchText,
      pages,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      onSearch,
      paginationSelect,
      getDateFormat,
      onPageSizeHandle,
      onViewDetail,
      userRegisterDetail,
      activityPasser,
      checkAll,
      checkAllHandler,
      handleChecked,
      selectedQualifiedUser,
      disabled,
      onVerifyPasserUser,
      countSelectedUsers,
      answerYesOrNot,
      errorNotificationToggle,
      errorTxt,
      isActiveOnProd,
      getUserDetail,
      getStatePath,
      statePath,
      selectedTab,
      handleExport,
      onConfirmExport,
      fileType
    };
  }
});
</script>

<style scoped lang="scss">
.accent-orange {
  accent-color: #ec7113;
}

.min-w-18 {
  min-width: 1.125rem;
}

.min-h-18 {
  min-height: 1.125rem;
}

.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
.text-bottom {
  padding-left: 22px;
}
</style>
