<!-- eslint-disable prettier/prettier -->
<template>
  <div class="items-center intro-y lg:flex-row relative pt-4">
    <div class="flex justify-end pb-7 absolute right-0 minus-top-48">
      <button
        class="hadow-md btn btn-primary bg-theme-1 text-sm font-medium h-full text-white flex items-center rounded-md
      "
        @click="onCertificateCheckAll(registeredUsers)"
      >
        <span class="pr-2"
          ><img
            alt="Midone Tailwind HTML Admin Template"
            class="w-5"
            src="@/assets/images/check-all-icon.svg"
        /></span>
        <span>เลือกทั้งหมดจากผู้เข้าร่วม</span>
      </button>
    </div>
    <div class="w-full h-9">
      <div class="flex w-full font-medium">
        <input
          id="tabulator-html-filter-value"
          v-model="searchText"
          type="text"
          class="w-2/5 form-control"
          placeholder="ค้นหาชื่อ นามสกุล เบอร์โทรศัพท์ และอีเมล"
          @keyup.enter="onSearch"
        />
        <button class="ml-4 w-20 shadow-md btn btn-primary" @click="onSearch">
          ค้นหา
        </button>

        <div class="dropdown">
          <div class="relative">
            <div
              v-if="checkedListFilter.length"
              class="w-3 h-3 bg-theme-1 absolute notificate-filter rounded-full"
            ></div>
            <button
              class="dropdown-toggle focus:outline-none flex items-center"
              aria-expanded="true"
            >
              <img
                class="slider ml-4 !outline-none flex items-center"
                src="@/assets/images/slider.svg"
              />
            </button>
          </div>
          <div class="w-48 dropdown-menu">
            <div
              class="p-2 dropdown-menu__content box border dark:bg-dark-1 shadow-2xl"
            >
              <a
                v-for="status in eventStatus"
                :key="status.key"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center text-xs p-2 py-3 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <span>
                  <input
                    :id="status.key"
                    v-model="checkedListFilter"
                    type="checkbox"
                    :value="status.key"
                    class="accent-orange min-w-18 min-h-18 flex justify-items-center items-center al cursor-pointer"
                    @change="handleCheckedFilter"
                  />
                </span>
                <label
                  :for="status.key"
                  class="flex content-center font-prompt justify-items-center items-center pl-2.5 cursor-pointer"
                  >{{ status.name }}</label
                >
              </a>
            </div>
          </div>
        </div>

        <!--Start Group Right Buttons-->
        <div class="flex justify-end w-full mt-4 sm:mt-0">
          <!--Start Display Announce Lists or not?-->
          <div
            v-if="announceOrNot"
            class="w-44 h-full mr-1.5 flex text-center items-center justify-center"
          >
            <div class="flex items-center justify-center mx-0 mb-0 pr-2.5 pb-3">
              <label class="toggle-control">
                <input
                  type="checkbox"
                  :checked="announceDisplayOrNot"
                  @change="updateStatusIsPublished"
                />
                <span class="control"></span>
              </label>
            </div>
            <div class="text-sm font-normal mx-0">
              แสดงประกาศ
            </div>
          </div>
          <!--End Display Announce Lists or not?-->

          <!--Start Copy link VIP-->
          <div class="mx-1.5">
            <button
              class="btn btn-outline-secondary px-5 h-full border border-theme-33 hover:bg-gray-200 text-theme-33 text-sm font-normal focus:ring-transparent"
              aria-expanded="false"
              @click="handleCopyUrl(linkRegisterVIP)"
            >
              ลิงก์ VIP
            </button>
          </div>

          <div class="mx-1.5">
            <!--Start Setting Email-->
            <router-link
              :to="{
                name: 'email-setting'
              }"
            >
              <button
                class="h-full shadow-md btn px-4 border-theme-33  text-theme-33 font-normal"
              >
                ตั้งค่าอีเมล
              </button>
            </router-link>
            <!--Start Setting Email-->
          </div>
          <!--Start Export-->
          <div class="mx-1.5">
            <button
              class="btn btn-outline-secondary px-4 h-full border border-theme-33 hover:bg-gray-200 text-theme-33 text-sm font-normal focus:ring-transparent"
              aria-expanded="false"
              @click="handleExport"
            >
              <DownloadIcon class="w-4 inline-block cursor-pointer" /><span
                class="pl-2"
                >Export</span
              >
            </button>
          </div>
          <!--Start Display Announce Lists or not?-->
          <div v-if="!announceOrNot" class="ml-1.5">
            <button
              :class="{ 'opacity-30': isDisabledAnnounce }"
              :disabled="isDisabledAnnounce"
              class="h-full shadow-md btn btn-primary mr-1.5"
              @click="onAnnounce"
            >
              ประกาศรายชื่อ
            </button>
          </div>
        </div>
        <!--End Group Right Buttons-->

        <!--Start Announce Modal-->
        <div
          id="announce-content-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content rounded-lg mt-40">
              <!-- BEGIN: Modal Body -->
              <div
                class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center"
              >
                <div class="col-span-12 sm:col-span-12 pt-7">
                  <label for="modal-form-1" class="form-label">
                    <AlertCircleIcon class="text-theme-31 icon-size-73" />
                  </label>
                  <div class="mt-0 text-xl font-medium leading-8 text-theme-32">
                    ยืนยันการประกาศรายชื่อ ?
                  </div>
                  <div class="mt-0.5 text-sm leading-5 text-theme-33">
                    ยืนยันการประกาศรายชื่อผู้ผ่านการคัดเลือกทั้งหมด, ใช่หรือไม่
                    ?
                  </div>
                </div>
              </div>
              <div class="flex justify-center w-full pb-10 pt-1">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-36 h-10 mr-2 btn btn-primary"
                  @click="onConfirmAnnounce"
                >
                  ยืนยันการประกาศ
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-20 btn btn-outline-secondary "
                >
                  ยกเลิก
                </button>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--End Announce Modal-->

        <!--Start Confirm Checkall Modal-->
        <div
          id="confirm-check-all-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content rounded-lg mt-40 w-512">
              <!-- BEGIN: Modal Body -->
              <div
                class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center"
              >
                <div class="col-span-12 sm:col-span-12 pt-7">
                  <label for="modal-form-1" class="form-label">
                    <AlertCircleIcon class="text-theme-31 icon-size-73" />
                  </label>
                  <div class="mt-0 text-xl font-medium leading-8 text-theme-32">
                    เลือกผู้เข้าร่วม ?
                  </div>
                  <div class="text-sm mt-1 font-normal text-theme-33 leading-5">
                    ยืนยันการให้ใบประกาศนียบัตรกับผู้ผ่านการคัดเลือกทั้งหมด,
                    ใช่หรือไม่ ?
                  </div>
                </div>
              </div>
              <div class="flex justify-center w-full pb-10 pt-1">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-20 h-10 mr-2 btn btn-primary"
                  @click="onConfirmCertificateCheckAll"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-20 btn btn-outline-secondary "
                >
                  ยกเลิก
                </button>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--End Confirm Checkall Modal-->

        <!--Start Modal Success-->
        <div
          id="success-content-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content h-72 mt-40 rounded-lg">
              <!-- BEGIN: Modal Body -->
              <div class="text-center">
                <div class="m-0 pt-16">
                  <div
                    class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
                  >
                    <div>
                      <CheckIcon
                        class="absolute left-2 top-2.5 text-white w-16 h-16"
                      />
                    </div>
                  </div>
                  <div class="mt-6 text-xl leading-8 text-theme-32">
                    ประกาศรายชื่อผู้ผ่านการคัดเลือกสำเร็จ
                  </div>
                </div>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--End Modal Success-->

        <!--Start Delete Modal-->
        <div
          id="delete-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content mt-40 rounded-lg">
              <!-- BEGIN: Modal Body -->
              <div
                class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center"
              >
                <div class="col-span-12 sm:col-span-12 pt-3">
                  <label for="modal-form-1" class="form-label">
                    <Trash2Icon class="text-theme-34 w-20 h-20" />
                  </label>
                  <div class="mt-1 text-xl leading-8 text-theme-32">
                    <p>ลบผู้ผ่านการคัดเลือก ?</p>
                  </div>
                  <div class="text-sm leading-1 mt-2.5">
                    <div class="flex justify-center">
                      <p>คุณต้องการลบ&nbsp;</p>
                      <p class="font-semibold">
                        {{ selectedUser?.verify_code }}
                        {{ selectedUser?.first_name }}
                        {{ selectedUser?.last_name }}
                      </p>
                    </div>
                    <div>
                      <p>ออกจากผู้ผ่านการคัดเลือก, ใช่หรือไม่ ?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center w-full pb-8 pt-0">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-36 h-10 mr-2 bg-theme-34 btn btn-primary"
                  @click="confirmDeleteEvent"
                >
                  ใช่, ลบรายการ
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-20 btn btn-outline-secondary"
                >
                  ยกเลิก
                </button>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--End Delete Modal-->

        <!--Select Type of Email Sending Modal-->
        <div
          id="type-send-email-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content h-full mt-40 rounded-lg">
              <!-- BEGIN: Modal Body -->
              <div
                class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center pt-8 pb-6"
              >
                <div class="col-span-12 sm:col-span-12">
                  <div class="text-xl leading-8 text-theme-32 font-medium">
                    เลือกประเภทการส่งอีเมล
                  </div>
                </div>
              </div>
              <div
                class="mx-0 px-10
              "
              >
                <button
                  class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
                  @click="checkIsEmailDraft(sendEmailUser)"
                >
                  รายละเอียดกิจกรรม
                </button>
              </div>
              <div
                class="mx-0 px-10 pt-3.5
              "
              >
                <button
                  class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
                  @click="checkIsCertDraft(sendEmailUser)"
                >
                  ใบประกาศนียบัตร
                </button>
              </div>
              <div class="flex justify-center w-full pt-7 pb-9">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 h-10 btn btn-outline-secondary border-1 rounded-md border-theme-33 text-theme-33"
                >
                  ยกเลิก
                </button>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--Select Type of Email Sending Modal-->

        <!--Select Type of Export Modal-->
        <div
          id="type-export-modal"
          class="modal font-prompt"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content h-full mt-40 rounded-lg">
              <!-- BEGIN: Modal Body -->
              <div
                class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center pt-8 pb-6"
              >
                <div class="col-span-12 sm:col-span-12">
                  <div
                    class="text-xl font-prompt leading-8 text-theme-32 font-medium"
                  >
                    เลือกรูปแบบไฟล์
                  </div>
                </div>
              </div>
              <div
                class="mx-0 px-10
              "
              >
                <button
                  class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
                  @click="onConfirmExport(fileType[0])"
                >
                  .xlsx
                </button>
              </div>
              <div
                class="mx-0 px-10 pt-3.5
              "
              >
                <button
                  class="bg-theme-1 w-full h-14 rounded-md text-white text-lg text-center font-normal"
                  @click="onConfirmExport(fileType[1])"
                >
                  .csv
                </button>
              </div>
              <div class="flex justify-center w-full pt-7 pb-9">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 h-10 btn btn-outline-secondary border-1 rounded-md border-theme-33 text-theme-33"
                >
                  ยกเลิก
                </button>
              </div>
              <!-- END: Modal Body -->
            </div>
          </div>
        </div>
        <!--Select Type of Export Modal-->
      </div>
    </div>

    <div class="grid grid-cols-4 gap-5 mt-6">
      <div
        class="all-applicants w-full h-24 rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ผ่านการคัดเลือกทั้งหมด
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            amountQualify
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
      <div
        class="w-full h-24 rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ผ่านการคัดเลือกทั่วไป
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            generalAmount
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
      <div
        class="w-full h-24 rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ผ่านการคัดเลือก VIP
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            vipAmount
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
      <div
        class="all-applicants w-full h-24 rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ได้ใบประกาศนียบัตรแล้ว
        </div>
        <div class="m0-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            amountCert
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
    </div>
    <!-- End candidate activities Summary-->

    <!--Start Table-->
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <table class="table table-report pt-4 font-prompt">
        <thead>
          <tr class="text-xs font-normal">
            <th
              class="whitespace-nowrap flex content-center justify-items-center items-center cursor-pointer"
            >
              รหัส
              <Tippy
                :content="
                  'รหัสผู้เข้าร่วมกิจกรรมจะขึ้นหลังจาก<br>กดประกาศรายชื่อแล้ว'
                "
                tag="a"
                href="javascript:;"
              >
                <AlertCircleIcon class="text-theme-33 block ml-2 w-4 h-4" />
              </Tippy>
            </th>
            <th class="whitespace-nowrap">ชื่อจริง</th>
            <th class="whitespace-nowrap">นามสกุล</th>
            <th class="whitespace-nowrap">เบอร์โทร</th>
            <th class="whitespace-nowrap">อีเมล</th>
            <th class="whitespace-nowrap">ช่วงอายุ</th>
            <th class="whitespace-nowrap">อาชีพ</th>
            <th class="whitespace-nowrap text-center">วันที่ลงทะเบียน</th>
            <th class="whitespace-nowrap text-center">ใบประกาศนียบัตร</th>
            <th class="whitespace-nowrap text-center">จัดการ</th>
            <th class="whitespace-nowrap text-center"></th>
          </tr>
        </thead>
        <tbody class="">
          <tr
            v-for="(user, key) in registeredUsers"
            :key="key"
            class="intro-x max-h-14 w-full text-xs"
          >
            <td
              class="
                break-all w-24 font-semibold
              "
            >
              {{ announceOrNot ? `${user.verify_code}` : `` }}
            </td>
            <td v-show="user.is_delete" colspan="6">
              <div
                :class="user.is_delete ? 'flex text-theme-1 items-center' : ''"
              >
                <div>
                  <AlertCircleIcon
                    v-if="user.is_delete"
                    class="block w-4 h-4 stroke-2"
                  />
                </div>
                <div
                  :class="
                    user.is_delete ? 'font-prompt font-medium pl-2 flex' : ''
                  "
                >
                  {{ user.is_delete ? "ผู้ใช้งานลบบัญชี" : user.first_name }}
                </div>
              </div>
            </td>
            <td v-show="!user.is_delete">
              <div
                :class="user.is_delete ? 'flex text-theme-1 items-center' : ''"
              >
                <div>
                  <AlertCircleIcon
                    v-if="user.is_delete"
                    class="block w-4 h-4"
                  />
                </div>
                <div
                  :class="
                    user.is_delete ? 'font-prompt font-medium pl-2 flex' : ''
                  "
                >
                  {{ user.is_delete ? "ผู้ใช้งานลบบัญชี" : user.first_name }}
                </div>
              </div>
            </td>
            <td
              v-if="!user.is_delete"
              :class="user.is_delete ? '' : 'break-all w-28'"
            >
              {{ user.last_name }}
            </td>
            <td
              v-if="!user.is_delete"
              :class="user.is_delete ? '' : 'break-all w-36'"
            >
              {{
                `${user.phone_number ? "0" : ""}` +
                  user.phone_number?.substring(3) || ""
              }}
            </td>
            <td
              v-if="!user.is_delete"
              :class="user.is_delete ? '' : 'break-all w-48'"
            >
              {{ user.email }}
            </td>
            <td
              v-if="!user.is_delete"
              :class="user.is_delete ? '' : 'break-all w-10'"
            >
              {{ user.age_name }}
            </td>
            <td
              v-if="!user.is_delete"
              :class="user.is_delete ? '' : 'break-all w-24'"
            >
              {{ user.career_name }}
            </td>
            <td class="break-all w-32">
              {{ getDateFormat(user.created_at) }}
            </td>
            <td class="w-20">
              <!--Lists who don't be announced-->
              <div
                v-if="announceOrNot === false"
                class="text-center flex justify-center items-center max-h-14"
              >
                <Tippy
                  :content="
                    'สร้างใบประกาศนียบัตรได้หลังจาก<br>ประกาศรายชื่อแล้วเท่านั้น'
                  "
                  tag="a"
                  href="javascript:;"
                >
                  <div
                    class="flex text-center items-center justify-center justify-items-center relative w-6 h-6 rounded-full border-theme-33 border-2 border-opacity-20"
                  >
                    <CheckIcon
                      class="absolute w-5 h-4 cursor-pointer text-theme-33 text-opacity-20"
                    />
                  </div>
                </Tippy>
              </div>
              <!--Lists who be announced-->
              <div v-else>
                <div
                  :class="
                    `text-center flex justify-center items-center max-h-14 ${
                      user.is_delete ? 'opacity-30' : ''
                    }`
                  "
                  :disabled="user.is_delete"
                >
                  <button
                    type="button"
                    :class="
                      `flex justify-center items-center w-6 h-6 rounded-full ${
                        user.isCertificate
                          ? 'bg-theme-1 text-white focus:ring-transparent'
                          : 'border-theme-33 border text-theme-33 focus:ring-transparent'
                      }`
                    "
                    @click="handleCertificate(user)"
                  >
                    <CheckIcon class="w-5 h-4" />
                  </button>
                </div>
              </div>
            </td>
            <td class="break-all w-24">
              <div
                class="text-center flex justify-center items-center max-h-14"
              >
                <div>
                  <div v-if="announceOrNot === false">
                    <Tippy
                      :content="
                        `ดาวน์โหลดใบประกาศนียบัตร<br>ได้หลังจากประกาศรายชื่อแล้วเท่านั้น`
                      "
                      tag="a"
                      href="javascript:;"
                    >
                      <DownloadIcon
                        class="mx-1.5 w-4 inline-block cursor-pointer opacity-30"
                      />
                    </Tippy>
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      :class="
                        `focus:ring-transparent ${
                          user.is_delete ? 'opacity-30' : ''
                        }`
                      "
                      :disabled="user.is_delete"
                      @click="handleDownloadCertificate(user.id)"
                    >
                      <DownloadIcon
                        class="mx-1.5 w-4 inline-block cursor-pointer"
                      />
                    </button>
                  </div>
                </div>
                <div>
                  <div v-if="announceOrNot === false">
                    <Tippy
                      :content="
                        'ส่งอีเมลได้หลังจาก<br>ประกาศรายชื่อแล้วเท่านั้น'
                      "
                      tag="a"
                      href="javascript:;"
                    >
                      <SendIcon
                        class="cursor-pointer inline-block w-4 mx-1.5 opacity-30"
                      />
                    </Tippy>
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      :class="
                        `focus:ring-transparent ${
                          user.is_delete ? 'opacity-30' : ''
                        }`
                      "
                      :disabled="user.is_delete"
                      @click="sendEmail(user)"
                    >
                      <SendIcon
                        class="cursor-pointer inline-block w-4 mx-1.5"
                      />
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    class="focus:ring-transparent"
                    @click="onViewDetail(user.id)"
                  >
                    <EyeIcon class="mx-1.5 w-4 inline-block cursor-pointer" />
                  </button>
                </div>
              </div>
            </td>
            <td class="w-10">
              <div class="flex justify-center">
                <button
                  type="button"
                  class="focus:ring-transparent flex items-center text-theme-34"
                  @click="onDelete(user)"
                >
                  <div>
                    <Trash2Icon
                      class="cursor-pointer inline-block w-4 mr-1.5"
                    />
                  </div>
                  <div>ลบ</div>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0 focus:ring-transparent cursor-pointer"
        @change="onPageSizeHandle"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>
    <!--End Table-->

    <div
      id="detail-registered-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto my-8 w-1/2">
        <div class="modal-content">
          <button
            type="button"
            class="v-dialog-close"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span class="v-btn__content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#5E5873"
                class="h-6 w-6 m-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
          <div class="pt-8 pb-12 modal-body px-16">
            <div class="text-center mt-6">
              <p class="bold text-2xl font-medium header-color">
                รายละเอียดผู้ลงทะเบียน
              </p>
              <p class="mt-2 text-theme-36">ข้อมูลในการกรอกของผู้ลงทะเบียน</p>
            </div>
            <div class="mt-8">
              <p class="text-lg font-medium mb-4">
                ส่วนที่ 1 : ข้อมูลส่วนบุคคลของผู้สมัครเข้าร่วมอบรม
              </p>
            </div>
            <div
              v-show="userRegisterDetail?.is_delete"
              class="grid grid-cols-1 bg-primary-12 rounded-lg mt-5"
            >
              <div class="py-2.5 px-3.5">
                <div class="flex font-medium items-center text-theme-1">
                  <div>
                    <AlertCircleIcon class="block w-4 h-4 stroke-2" />
                  </div>
                  <div
                    class="
                    font-prompt font-medium pl-1.5 flex
                  "
                  >
                    ผู้ใช้งานลบบัญชี
                  </div>
                </div>
                <div class="text-theme-33 pt-1 text-bottom">
                  ไม่มีข้อมูลผู้สมัครในระบบ
                  เนื่องจากผู้ใช้งานขอลบบัญชีออกจากระบบแล้ว
                </div>
              </div>
            </div>
            <div
              v-if="!userRegisterDetail?.is_delete"
              class="grid grid-cols-3 grid-flow-row gap-x-16 gap-y-7 mt-5"
            >
              <div>
                <p>ชื่อจริง</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.first_name || "-" }}
                </p>
              </div>
              <div>
                <p>นามสกุล</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.last_name || "-" }}
                </p>
              </div>
              <div>
                <p>ชื่อเล่น</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.nick_name || "-" }}
                </p>
              </div>
              <div>
                <p>จังหวัดที่อาศัยอยู่ในปัจจุบัน</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.province_name || "-" }}
                </p>
              </div>
              <div>
                <p>วันเดือนปีเกิด</p>
                <p class="font-semibold">
                  {{
                    $h.formatDate(
                      userRegisterDetail?.date_of_birth,
                      "DD/MM/YYYY"
                    ) || "-"
                  }}
                </p>
              </div>
              <div>
                <p>อายุ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.age_name || "-" }}
                </p>
              </div>
              <div>
                <p>เพศ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.gender_name || "-" }}
                </p>
              </div>
              <div>
                <p>ระดับการศึกษา</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.education_name || "-" }}
                </p>
              </div>
              <div>
                <p>รายได้ครัวเรือน</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.income_name || "-" }}
                </p>
              </div>
              <div>
                <p>อาชีพ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.career_name || "-" }}
                </p>
              </div>
              <div>
                <p>เบอร์โทร</p>
                <p class="font-semibold">
                  {{
                    `${userRegisterDetail?.phone_number ? "0" : ""}` +
                      userRegisterDetail?.phone_number?.substring(3) || ""
                  }}
                </p>
              </div>
              <div>
                <p>Line ID</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.line_id || "-" }}
                </p>
              </div>
              <div>
                <p>อีเมล</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.email || "-" }}
                </p>
              </div>
            </div>
            <hr class="mt-10 mb-7" />
            <p class="text-lg font-medium mb-4">
              ส่วนที่ 2 : แบบสำรวจความคิดเห็น ความคาดหวัง และการนำไปใช้จริง
              กิจกรรม "{{ userRegisterDetail?.activity?.name }}"
            </p>
            <div>
              <p class="text-sm font-medium">
                1. ท่านเคยเข้าคอร์สอบรม
                เรื่องการดูแลแบบประคับประคองการดูแลคุณภาพชีวิตระยะท้ายมาก่อนหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull; {{ answerYesOrNot(userRegisterDetail?.is_trained) }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                2. ท่านเคยเข้าคอร์สอบรม เรื่องการดูแลคุณภาพชีวิตระยะท้ายของ 5
                มิติองค์ความรู้มิติใดบ้าง
              </p>
              <ul
                v-if="userRegisterDetail?.courses"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li
                  v-for="course in userRegisterDetail?.courses"
                  :key="course.id"
                >
                  &bull; {{ course.name }}
                </li>
              </ul>
              <ul
                v-show="!userRegisterDetail?.courses"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  &bull; ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                3. ท่านเคยเข้าคอร์สอบรม เรื่องการดูแลแบบประคับประคอง
                การดูแลคุณภาพชีวิตระยะท้ายกับชีวามิตรมาก่อนหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ answerYesOrNot(userRegisterDetail?.is_trained_cvm) }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                4. ท่านคาดหวังอะไรบ้างจากการเข้าเรียน "{{
                  userRegisterDetail?.activity?.name
                }}" ในครั้งนี้(ตอบได้มากกว่า 1 ข้อ)
              </p>
              <ul
                v-if="userRegisterDetail?.expects"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li
                  v-for="expect in userRegisterDetail?.expects"
                  :key="expect.id"
                >
                  &bull; {{ expect.name }}
                </li>
              </ul>
              <ul
                v-show="!userRegisterDetail?.expects"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  &bull; ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                5. จงบอกเหตุผลว่าทำไมท่านถึงอยากเรียน "{{
                  userRegisterDetail?.activity?.name
                }}" ในครั้งนี้
              </p>
              <ul
                :class="[
                  `px-2.5 mt-2.5 text-sm font-normal ${
                    userRegisterDetail?.reason
                      ? 'text-theme-36'
                      : 'text-theme-31'
                  }`
                ]"
              >
                <li>&bull; {{ userRegisterDetail?.reason || "ไม่ได้กรอก" }}</li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                6. ท่านยินดีทำแบบสอบถามทุกฉบับที่กำหนดไว้ในกิจกรรมนี้หรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ userRegisterDetail?.is_pleased ? "ยอมรับ" : "ปฏิเสธ" }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                7. ท่านสะดวกเข้าร่วมกิจกรรมตามวัน - เวลา
                ที่ระบุไว้ในกิจกรรมหรือไม่
              </p>
              <ul class="px-2.5 mt-2.5 text-sm font-normal text-theme-36">
                <li>
                  &bull;
                  {{ userRegisterDetail?.train_date_name || "ไม่ได้กรอก" }}
                </li>
              </ul>
            </div>
            <hr class="my-4" />
            <div>
              <p class="text-sm font-medium">
                8. ข้อเสนอแนะอื่นๆ (ถ้ามี)
              </p>
              <ul
                :class="[
                  `px-2.5 mt-2.5 text-sm font-normal ${
                    userRegisterDetail?.suggest
                      ? ' text-theme-36'
                      : ' text-theme-31'
                  }`
                ]"
              >
                <li>
                  &bull; {{ userRegisterDetail?.suggest || "ไม่ได้กรอก" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Detail User Modal-->

    <!--Start Not Have Detail Email Modal-->
    <div
      id="not-have-detail-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยังไม่มีรายละเอียดอีเมลสำหรับส่ง
            </div>
            <div class="pt-2 px-14 leading-5 text-sm text-theme-32">
              ไม่สามารถกดส่งอีเมลได้ เนื่องจากคุณยังไม่ได้กรอก<br />รายละเอียดอีเมลกรุณากรอกรายละเอียดหรือรูปแบบอีเมลสำหรับการส่งให้ผู้เข้าร่วมกิจกรรม
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <router-link
              :to="{
                name: 'email-setting'
              }"
            >
              <button
                type="button"
                data-dismiss="modal"
                class="w-36 h-10 mr-2 btn btn-primary"
              >
                ตั้งค่าอีเมล
              </button>
            </router-link>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Not Have Detail Email Modal-->

    <!--Start Not Have Cert Modal-->
    <div
      id="not-have-cert-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยังไม่มีรายละเอียดใบประกาศนียบัตรสำหรับส่ง
            </div>
            <div class="pt-2 px-12 leading-5 text-sm text-theme-32">
              ไม่สามารถกดส่งใบประกาศนียบัตรได้
              เนื่องจากคุณยังไม่ได้กรอกรายละเอียดใบประกาศนียบัตรกรุณากรอกรายละเอียดหรือรูปแบบใบประกาศนียบัตรสำหรับการส่งให้ผู้เข้าร่วมกิจกรรม
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
              @click="$emit('noCertificate')"
            >
              อัพโหลดรูปแบบ
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Not Have Cert Modal-->

    <!--Start Have Detail Email Modal-->
    <div
      id="have-detail-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 inline-block h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32 d-flex">
              ยืนยันการส่งรายละเอียดกิจกรรม ?
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              คุณต้องการส่งรายละเอียดกิจกรรม<br />ไปยัง
              <span class="text-black">{{ sendEmailUserContinue?.email }}</span
              >, ใช่หรือไม่ ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
              @click="
                onConfirmSendEmail(sendEmailUserContinue.id, emailPath[0])
              "
            >
              ยืนยันการส่งอีเมล
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Have Detail Email Modal-->

    <!--Start Have Cert Modal-->
    <div
      id="have-cert-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยืนยันการส่งใบประกาศนียบัตร ?
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              คุณต้องการส่งใบประกาศนียบัตร<br />ไปยัง
              <span class="text-black">{{ sendEmailUserContinue?.email }}</span
              >, ใช่หรือไม่ ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-56 h-12 mr-2 btn btn-primary"
              @click="
                onConfirmSendCertificate(sendEmailUserContinue.id, emailPath[1])
              "
            >
              ยืนยันการส่งใบประกาศนียบัตร
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Have Detail Email Modal-->

    <!--Modal Send Email Success-->
    <div
      id="error-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-12">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-4">ผิดพลาด !!</div>
              <div class="mt-0 leading-7 text-xl font-medium text-theme-32">
                ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่<br />อีกครั้ง
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Email Success-->
    <div
      id="success-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                ส่งอีเมลสำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Certificate Success-->
    <div
      id="success-send-certificate-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                ส่งใบประกาศนียบัตรสำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Certificate Success-->

    <!--Modal Copy Clipboard Success-->
    <div
      id="copy-url-success-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                คัดลอกลิงก์สำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Copy Clipboard Success-->

    <!--Modal Delete Success-->
    <div
      id="delete-success-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                ลบผู้ผ่านการคัดเลือกเรียบร้อยแล้ว
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Delete Success-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog w-460">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-4">ผิดพลาด !!</div>
              <div
                class="mt-0 text-xl font-medium leading-8 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Error-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="success-send-real-email" class="modal-dialog w-460">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-20">
              <div
                class="relative w-20 h-20 bg-theme-35 rounded-full inline-block"
              >
                <CheckIcon
                  class="absolute left-2 top-2.5 text-white w-16 h-16"
                />
              </div>
              <div
                class="mt-5 text-xl font-medium leading-8 font-prompt text-theme-32"
              >
                {{ successTxt }}
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Success-->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed, nextTick } from "vue";
import Toastify from "toastify-js";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { useRoute } from "vue-router";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  setup() {
    const route = useRoute();

    const title = ref();
    const registeredUsers = ref([]);
    const selectedUser = ref(null);
    const sendEmailUser = ref(null);
    const sendEmailUserContinue = ref(null);
    const searchText = ref();
    const pageSize = ref([10, 20, 30, 40]);
    const pages = ref([]);
    const page = ref(1);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const userRegisterDetail = ref();
    const activityPasser = ref([]);
    const checkAll = ref(false);
    const disabled = ref(false);
    const announceOrNot = ref(false);
    const announceDisplayOrNot = ref(false);
    const activitiesData = ref(null);
    const amountCert = ref(0);
    const amountQualify = ref(0);
    const deleteEvent = ref({});
    const successTxt = ref("");
    const errorTxt = ref("");
    const userDeleted = ref();
    const isCertificate = ref(false);
    const userGetCer = ref([]);
    const linkRegisterVIP = ref(null);
    const emailPath = ref(["announcement", "certificate"]);
    const checkedListFilter = ref([]);
    const allRegisteredUser = ref([]);
    const generalAmount = ref(0);
    const vipAmount = ref(0);
    const registerUsersFiltered = ref([]);
    const selectedTab = ref();
    const statePath = ref();
    const fileType = ref(["xlsx", "csv"]);

    const eventStatus = ref([
      {
        name: "ได้ใบประกาศนียบัตร",
        key: "IsCertificateTrue"
      },
      {
        name: "ไม่ได้ใบประกาศนียบัตร",
        key: "IsCertificateFalse"
      },
      {
        name: "ผู้สมัครทั่วไป",
        key: "IsRegisterGeneral"
      },
      {
        name: "ผู้สมัคร VIP",
        key: "IsRegisterVIP"
      }
    ]);
    const selectedStatus = ref({ name: "ทั้งหมด", key: undefined });

    const isDisabledAnnounce = computed(() => {
      return registeredUsers.value.length === 0;
    });

    const onSearch = () => {
      pageSelect.value = 1;
      fetchUserRegisters();
    };

    const handleDownloadCertificate = async registerId => {
      const res = await getheringService.getCertificateUser(registerId);
      const binaryCertificateFile = res.data.data.item.certificate;
      $h.downloadImageBase64(registerId, binaryCertificateFile);
    };

    const onConfirmSendEmail = async (userId, emailPath) => {
      try {
        await getheringService.sendEmailIndividual(userId, emailPath);
        cash(`#success-send-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#success-send-email-modal`).modal("hide");
        }, 1500);
        fetchUserRegisters();
      } catch (error) {
        cash(`#error-send-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#error-send-email-modal`).modal("hide");
        }, 1500);
      }
    };

    const onConfirmSendCertificate = async (userId, emailPath) => {
      try {
        await getheringService.sendEmailIndividual(userId, emailPath);
        cash(`#success-send-certificate-modal`).modal("show");
        setTimeout(() => {
          cash(`#success-send-certificate-modal`).modal("hide");
        }, 1500);
        fetchUserRegisters();
      } catch (error) {
        cash(`#error-send-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#error-send-email-modal`).modal("hide");
        }, 1500);
      }
    };

    const handleCheckedFilter = () => {
      fetchUserRegisters();
    };

    const answerYesOrNot = answer => {
      return answer ? "เคย" : "ไม่เคย";
    };

    const onAnnounce = () => {
      cash(`#announce-content-modal`).modal("show");
    };
    const onConfirmAnnounce = async () => {
      try {
        await getheringService.announcementActivity(route.params.id);
        announceOrNot.value = true;
        cash(`#success-content-modal`).modal("show");
        await fetchUserRegisters();
        setTimeout(() => {
          cash(`#success-content-modal`).modal("hide");
        }, 1200);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const onCertificateCheckAll = () => {
      cash(`#confirm-check-all-modal`).modal("show");
    };

    const onConfirmCertificateCheckAll = async () => {
      try {
        for (let user of registeredUsers.value) {
          user.isCertificate = true;
        }
        for (let user of allRegisteredUser.value) {
          user.isCertificate = true;
        }
        await getheringService.updateAllQualifiedUser(route.params.id);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const paginationSelect = page => {
      pageSelect.value = page;
      fetchUserRegisters();
    };

    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY, H:mm");
    };
    const onPageSizeHandle = () => {
      page.value = 1;
      fetchUserRegisters();
    };

    const onDelete = async user => {
      selectedUser.value = $h.toRaw(user);
      cash("#delete-modal").modal("show");
    };

    const onViewDetail = async registerId => {
      try {
        const res = await getheringService.getUserByRegisterId(registerId);
        userRegisterDetail.value = res.data.data.item;
        cash("#detail-registered-modal").modal("show");
      } catch (err) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const handleCopyUrl = linkRegisterVIP => {
      const vipLink = `${process.env.VUE_APP_CLIENT}/activities/${linkRegisterVIP}/vip-register`;
      navigator.clipboard.writeText(vipLink);
      cash(`#copy-url-success-modal`).modal("show");
      setTimeout(() => {
        cash(`#copy-url-success-modal`).modal("hide");
      }, 3000);
    };

    const handleExport = async () => {
      cash("#type-export-modal").modal("show");
    };

    const onConfirmExport = async fileType => {
      try {
        const res = await getheringService.getExportedFiles(
          route.params.id,
          statePath.value,
          { filter: checkedListFilter.value.join(","), fileType: fileType }
        );
        $h.downloadBinaryFile(res.data, fileType);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const sendEmail = userData => {
      cash("#type-send-email-modal").modal("show");
      if (!userData) return;
      sendEmailUser.value = $h.toRaw(userData);
    };

    const checkIsEmailDraft = user => {
      sendEmailUserContinue.value = $h.toRaw(user);
      const isActivityHaveEmail = $h.toRaw(activitiesData.value.is_email);
      cash("#type-send-email-modal").modal("hide");
      if (isActivityHaveEmail) {
        cash("#have-detail-email-modal").modal("show");
      } else {
        cash("#not-have-detail-email-modal").modal("show");
      }
    };
    const checkIsCertDraft = user => {
      cash("#delete-modal").modal("hide");
      sendEmailUserContinue.value = $h.toRaw(user);
      const isActivityHaveCert = $h.toRaw(activitiesData.value.is_certificate);
      cash("#type-send-email-modal").modal("hide");
      if (isActivityHaveCert) {
        cash("#have-cert-modal").modal("show");
      } else {
        cash("#not-have-cert-modal").modal("show");
      }
    };

    const confirmDeleteEvent = async () => {
      const userId = await $h.toRaw(selectedUser.value.id);
      await getheringService
        .deleteQualifiedUser(userId)
        .then(() => {
          cash(`#delete-success-modal`).modal("show");
          setTimeout(() => {
            cash(`#delete-success-modal`).modal("hide");
          }, 1200);
          fetchUserRegisters();
        })
        .catch(() => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };

    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-send-real-email")
            .clone()
            .removeClass("hidden")[0],
          duration: 1200,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1200,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const updateStatusIsPublished = async event => {
      if (!event || !route.params.id) return;
      try {
        await getheringService.changeAnnouncedDisplay(route.params.id);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const fetchUserRegisters = async () => {
      try {
        const detail = await getheringService.getDetailGathering(
          route.params.id
        );
        title.value = detail.data.data.title;
        const resAll = await getheringService.getQualifiedList(route.params.id);
        allRegisteredUser.value = resAll?.data?.data?.items?.Registers;

        const res = await getheringService.getQualifiedList(route.params.id, {
          filter: checkedListFilter.value.join(","),
          page: pageSelect.value,
          perPage: pageSizeSelect.value,
          search: searchText?.value || undefined
        });
        activitiesData.value = res.data.data.items;

        const {
          certificate_amount,
          qualify_amount,
          general_amount,
          vip_amount,
          is_announcement,
          is_announcement_display,
          url_slug,
          Registers
        } = res.data.data.items;

        registerUsersFiltered.value = Registers;
        linkRegisterVIP.value = $h.toRaw(url_slug);
        announceDisplayOrNot.value = is_announcement_display;
        announceOrNot.value = is_announcement;
        amountCert.value = certificate_amount;
        amountQualify.value = qualify_amount;
        generalAmount.value = general_amount;
        vipAmount.value = vip_amount;

        registeredUsers.value =
          res?.data?.data?.items?.Registers.map(item => ({
            ...item,
            isCertificate: item.is_certificate
          })) ?? [];
        const pageResult = $h.calculatePagination({
          totalItems: res.data.data.total,
          pageSize: pageSizeSelect.value,
          currentPage: pageSelect.value
        });
        pages.value = pageResult.pages;
        totalPages.value = pageResult.totalPages;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const handleCertificate = async user => {
      try {
        //.... certificate user via API
        await getheringService.updateQualifiedUser($h.toRaw(user.id));
        user.isCertificate = !user.isCertificate;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const getStatePath = () => {
      selectedTab.value = JSON.parse(localStorage.getItem("selectedTab"));
      if (selectedTab.value.id === 3) {
        statePath.value = "qualify";
      }
    };

    onMounted(() => {
      fetchUserRegisters();
      getStatePath();
    });

    return {
      registeredUsers,
      searchText,
      pages,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      onSearch,
      paginationSelect,
      getDateFormat,
      onPageSizeHandle,
      onViewDetail,
      userRegisterDetail,
      activityPasser,
      checkAll,
      disabled,
      amountCert,
      amountQualify,
      selectedStatus,
      eventStatus,
      onAnnounce,
      onConfirmAnnounce,
      updateStatusIsPublished,
      checkIsEmailDraft,
      announceOrNot,
      announceDisplayOrNot,
      sendEmail,
      confirmDeleteEvent,
      deleteEvent,
      successNotificationToggle,
      checkIsCertDraft,
      successTxt,
      errorTxt,
      errorNotificationToggle,
      userDeleted,
      selectedUser,
      onDelete,
      isCertificate,
      userGetCer,
      handleCertificate,
      onConfirmSendEmail,
      onConfirmSendCertificate,
      sendEmailUser,
      sendEmailUserContinue,
      emailPath,
      answerYesOrNot,
      isDisabledAnnounce,
      handleCopyUrl,
      linkRegisterVIP,
      checkedListFilter,
      handleCheckedFilter,
      onCertificateCheckAll,
      onConfirmCertificateCheckAll,
      allRegisteredUser,
      handleDownloadCertificate,
      generalAmount,
      vipAmount,
      registerUsersFiltered,
      getStatePath,
      selectedTab,
      statePath,
      handleExport,
      onConfirmExport,
      fileType
    };
  }
});
</script>

<style scoped lang="scss">
.accent-orange {
  accent-color: #ec7113;
}

.border-1 {
  border-width: 1px;
}

.min-w-18 {
  min-width: 1.125rem;
}

.min-h-18 {
  min-height: 1.125rem;
}

.w-460 {
  width: 460px;
}

.w-137 {
  width: 137px;
}

.header-color {
  color: #1d310f;
}

.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}

.minus-top-48 {
  top: -48px;
}

.icon-size-73 {
  width: 87px;
  height: 87px;
}

.w-512 {
  width: 490px;
}

.slider {
  width: 74px;
}

.dropdown-menu__content {
  margin-left: 150px;
}

.notificate-filter {
  top: -0.2rem;
  right: -0.3rem;
}
</style>
